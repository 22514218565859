import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/css/MacBookAir/Contact.css';
import ContactInfo from './ContactInfo';
import { TopObserver, BottomObserver } from '../utils/Animation';
import Loader from './loader';

interface FormData {
    name: string;
    surname: string;
    email: string;
    message: string;
    number: string;
    // country:string;
    service: string[];
}

const countryCodes = [
    { "country": "India", "code": "+91" },
    { "country": "Pakistan", "code": "+92" },
    { "country": "Bangladesh", "code": "+880" },
    { "country": "Nepal", "code": "+977" },
    { "country": "Bhutan", "code": "+975" },
    { "country": "Sri Lanka", "code": "+94" },
    { "country": "Maldives", "code": "+960" },
    { "country": "China", "code": "+86" },
    { "country": "Myanmar", "code": "+95" },
    { "country": "Afghanistan", "code": "+93" },
    { "country": "United States", "code": "+1" },
    { "country": "Canada", "code": "+1" },
    { "country": "United Kingdom", "code": "+44" },
    { "country": "Australia", "code": "+61" },
    { "country": "Germany", "code": "+49" },
    { "country": "France", "code": "+33" },
    { "country": "Japan", "code": "+81" },
    { "country": "Brazil", "code": "+55" },
    { "country": "South Africa", "code": "+27" },
    { "country": "Russia", "code": "+7" },
    { "country": "Mexico", "code": "+52" },
    { "country": "Italy", "code": "+39" },
    { "country": "Spain", "code": "+34" },
    { "country": "Netherlands", "code": "+31" },
    { "country": "Sweden", "code": "+46" },
    { "country": "Switzerland", "code": "+41" },
    { "country": "South Korea", "code": "+82" },
    { "country": "New Zealand", "code": "+64" }
]


const Contacts: React.FC = () => {
    const [loading, setLoading] = useState<Boolean>(false);
    const [countryCode, setCountryCode] = useState<string>('+91');
    const [formData, setFormData] = useState<FormData>({
        name: '',
        surname: '',
        email: '',
        message: '',
        number: '',
        // country:'',
        service: ['Web Development'],
    });

    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);
    const [showAlertPopup, setShowAlertPopup] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleServiceChange = (service: string, e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setFormData((prevState) => ({
            ...prevState,
            service: prevState.service.includes(service)
                ? prevState.service.filter((s) => s !== service)
                : [...prevState.service, service]
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.service.length === 0) {
            window.alert('Please choose a service!');
            return;
        }
        setShowConfirmationPopup(true);
    };

    const confirmSubmission = async () => {

        try {
            setLoading(true);
            // Send form data to the backend
            // console.log({ ...formData, number: `${countryCode} ${formData.number}` });
            // return;

            await axios.post('https://ripple-nexus-website.onrender.com/api/submit-form', { ...formData, number: `${countryCode} ${formData.number}` });
            setLoading(false);
            setShowConfirmationPopup(false);
            //clearing old data
            setFormData({
                name: '',
                surname: '',
                email: '',
                message: '',
                number: '',
                // country:'',
                service: [''],
            });
            setShowAlertPopup(true);
        } catch (error) {
            window.alert("Getting some error posting your form!")
            console.error('Error submitting form', error);
        } finally {
            setLoading(false);
        }


    };

    const closeAlertPopup = () => {
        setShowAlertPopup(false);
    };

    // Animation logic
    useEffect(() => {
        const headerTitle = document.querySelectorAll('.contact-header-title') as NodeListOf<HTMLElement>;
        headerTitle.forEach((element) => {
            TopObserver.observe(element);
        });

        const headerDescription = document.querySelectorAll('.contact-header-description') as NodeListOf<HTMLElement>;
        headerDescription.forEach((element) => {
            BottomObserver.observe(element);
        });

        // const contactContent = document.querySelectorAll('.contact-content') as NodeListOf<HTMLElement>;
        // contactContent.forEach((element, index) => {
        //     LeftObserver.observe(element);
        // });

    }, []);

    return (
        <>

            <div id='contact-us' className="contact-container">
                <div className="contact-header">
                    <h1 className='contact-header-title'>Get In Touch</h1>
                    <p className='contact-header-description'>
                        We'd love to hear from you! Whether you have questions, need more information, or want to discuss your project, feel free to reach out. Our team is here to help and will get back to you as soon as possible.
                    </p>
                </div>
                <div className="contact-content">
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <h2 className='sendMessage'>Send us a Message</h2>
                        <label htmlFor="name">First Name</label>
                        <input type="text" id="name" placeholder="First Name" value={formData.name} onChange={handleChange} required />

                        <label htmlFor="surname">Surname</label>
                        <input type="text" id="surname" placeholder="Surname" value={formData.surname} onChange={handleChange} required />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="Email" value={formData.email} onChange={handleChange} required />

                        <label htmlFor='number'>Contact</label>
                        <div className='md:w-[78%] flex gap-1 items-center p-0 mb-0'>
                            <select className='border-2 rounded-lg w-[4em] py-2 -mt-2' onChange={(e) => setCountryCode(e.target.value)}>
                                {
                                    countryCodes.map((item, index) => {
                                        return (
                                            <option key={index} value={item.code}>{item.code} - {item.country}</option>
                                        )
                                    })
                                }
                                <option></option>
                            </select>
                            <input className='w-1/2' type='number' id='number' placeholder='Whatsapp number' value={formData.number} onChange={handleChange} min={4} required />
                        </div>
                        <label htmlFor="message">Message</label>
                        <textarea id="message" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>

                        <div className="button-group">
                            {['Web Development', 'UI/UX Design', 'Graphics Design', 'Logo and Branding Design', 'Resume/CV Writing', 'LinkedIn Optimization'].map((service) => (
                                <button
                                    type="button"
                                    key={service}
                                    id={service}
                                    className={`px-4 py-2 rounded-md ${formData.service.includes(service) ? 'bg-[#457FD7] text-white' : 'border border-black text-black'}`}
                                    onClick={(event) => handleServiceChange(service, event)}
                                >
                                    {service}
                                </button>
                            ))}
                        </div>
                        <button className='form-submit-button' type="submit">Submit</button>
                    </form>
                    <ContactInfo />
                </div>

                {showConfirmationPopup && (
                    <>
                        <div className="popup">
                            <div className="popup-content">
                                <h2 style={{ color: "black" }}>Confirm Submission</h2>
                                <p style={{ color: "black" }}>Are you sure you want to submit the form?</p>
                                <div className='button-group'>
                                    <button onClick={confirmSubmission}>Yes</button>
                                    <button onClick={() => setShowConfirmationPopup(false)}>No</button>
                                </div>
                            </div>
                        </div>
                        {
                            loading && (<Loader />)
                        }

                    </>
                )}

                {showAlertPopup && (
                    <div className="alert-popup">
                        <div className="alert-popup-content">
                            <p style={{ color: "black" }}>Your request has been submitted!</p>
                            <button onClick={closeAlertPopup}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Contacts;


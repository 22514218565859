import React from 'react';
// import CompanyVisionNew from '../components/CompanyVisionNew';
// import ContactForm from '../components/ContactForm';
import Contacts from '../components/Contacts';
import { CompanyVision } from '../components/CompanyVision/CompanyVision';

const ContactUsPage = () => {
  return (
    <>
      <CompanyVision />
      {<Contacts />}
    </>
  );
};

export default ContactUsPage;

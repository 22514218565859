// TermsOfService.tsx
import React from "react";
import "../styles/css/TermsOfService.css"; // Import the CSS file

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>
      <p><strong>Effective Date:</strong> 1st August, 2024</p>
      <p><strong>Last Updated:</strong> 26th December, 2024</p>

      <p>Welcome to Ripple Nexus! These Terms of Service (“Terms”) constitute a legally binding agreement between Ripple Nexus (“Company”, “we”, or “us”) and you (“Client” or “you”). By accessing our website (<a href="https://www.ripplenexus.tech">https://www.ripplenexus.tech</a>) or using any of our services, you agree to comply with and be bound by these Terms. If you disagree with any part of these Terms, you must refrain from using our services.</p>

      <div className="section-header">
        <h2>1. Definitions</h2>
      </div>
      <ul>
        <li><strong>“Company”</strong> refers to Ripple Nexus, including its subsidiaries, officers, agents, and contractors.</li>
        <li><strong>“Client”</strong> refers to the individual, company, or entity engaging Ripple Nexus for services.</li>
        <li><strong>“Services”</strong> encompasses all offerings provided by Ripple Nexus, including website development, customized software solutions, branding, digital marketing, app development, AI chatbot creation, and career enhancement services.</li>
        <li><strong>“Deliverables”</strong> are the final products or results of services provided to the client.</li>
        <li><strong>“Agreement”</strong> includes these Terms, along with any written or electronic contracts, service level agreements, or work orders.</li>
      </ul>

      <div className="section-header">
        <h2>2. Acceptance of Terms</h2>
      </div>
      <p>By accessing or using our services, you confirm the following:</p>
      <ul>
        <li>You are legally authorized to enter into this agreement digitally on behalf of yourself or your organization.</li>
        <li>You acknowledge that all agreements and communications with Ripple Nexus are conducted online.</li>
        <li>You agree to provide accurate, timely, and complete information necessary for service delivery.</li>
      </ul>
      <p><strong>Location:</strong> Sector 62, Noida, Uttar Pradesh, 201311</p>
      <p><strong>Phone:</strong> +91 7599756826</p>
      <p><strong>Email:</strong> <a href="mailto:contact@ripplenexus.tech">contact@ripplenexus.tech</a></p>
      <p><strong>Website:</strong> <a href="https://www.ripplenexus.tech">https://www.ripplenexus.tech</a></p>

      <div className="section-header">
        <h2>3. Scope of Services</h2>
      </div>
      <p>Ripple Nexus provides a comprehensive range of online-based services, tailored to meet the needs of businesses and individuals in the digital realm. These services are delivered exclusively via digital platforms and can be accessed remotely.</p>
      <h3>3.1 Website Development</h3>
      <ul>
        <li><strong>Business Websites:</strong> Tailored for industries seeking professional online representation.</li>
        <li><strong>E-Commerce Websites:</strong> Featuring secure payment gateways, product catalog management, and seamless user experiences.</li>
        <li><strong>Customized Solutions:</strong> Designed to address unique business requirements with personalized functionalities.</li>
      </ul>
      <h3>3.2 Customized Software Development</h3>
      <ul>
        <li><strong>Enterprise Resource Planning (ERP) Systems:</strong> Centralized tools for managing core business processes.</li>
        <li><strong>Customer Relationship Management (CRM) Systems:</strong> Enhancing customer engagement and retention.</li>
        <li><strong>Custom Automation Tools:</strong> Streamlining workflows to improve productivity and reduce costs.</li>
        <li><strong>Industry-Specific Software:</strong> Bespoke solutions for specialized fields like healthcare, education, and retail.</li>
      </ul>
      <h3>3.3 Mobile and Cloud App Development</h3>
      <ul>
        <li><strong>Mobile Applications:</strong> Intuitive apps for Android and iOS, ensuring superior user experience.</li>
        <li><strong>Cloud-Based Applications:</strong> Scalable solutions for real-time access and collaboration.</li>
      </ul>
      <h3>3.4 Branding and Graphic Design</h3>
      <ul>
        <li><strong>Logo Creation:</strong> Unique, high-quality designs reflecting your business identity.</li>
        <li><strong>Visual Identity Packages:</strong> Branding guidelines, typography, and marketing material design.</li>
        <li><strong>UI/UX Design:</strong> Advanced interface designs optimized for user engagement.</li>
      </ul>
      <h3>3.5 Career Enhancement Services</h3>
      <ul>
        <li><strong>Resume and CV Writing:</strong> Focused on industry relevance and role-specific highlights.</li>
        <li><strong>LinkedIn Optimization:</strong> Boosting visibility and increasing networking potential.</li>
        <li><strong>Portfolio Development:</strong> Online portfolios showcasing achievements and skills.</li>
      </ul>
      <h3>3.6 AI Chatbot Development</h3>
      <ul>
        <li><strong>Features:</strong> Natural language processing (NLP), multi-channel integration, and automated responses.</li>
        <li><strong>Applications:</strong> Customer support, sales automation, and lead generation.</li>
      </ul>
      <h3>3.7 Digital Marketing and SEO</h3>
      <ul>
        <li><strong>SEO Strategies:</strong> Technical and content optimization for better search rankings.</li>
        <li><strong>Social Media Marketing:</strong> Increasing brand visibility and audience engagement.</li>
        <li><strong>Content Creation:</strong> Blogs, articles, and social media posts tailored to target audiences.</li>
      </ul>

      <div className="section-header">
        <h2>4. Client Obligations</h2>
      </div>
      <ul>
        <li>Timely Communication: Respond promptly to requests for information, approvals, or feedback.</li>
        <li>Accurate Information: Provide complete and accurate information required for service execution.</li>
        <li>Payment Compliance: Make payments on or before agreed deadlines to avoid service disruptions.</li>
        <li>Proper Use: Use the deliverables strictly for lawful purposes and as intended under the Agreement.</li>
        <li>Confidentiality: Protect any proprietary tools or methods provided by Ripple Nexus. Failure to meet these obligations may result in delays, additional charges, or termination of services.</li>
      </ul>

      <div className="section-header">
        <h2>5. Payment Terms</h2>
      </div>
      <h3>5.1 Invoicing</h3>
      <ul>
        <li>Clients will receive an invoice detailing the agreed service fees.</li>
        <li>Payments must be made within the due date specified on the invoice.</li>
        <li>Payments must be made electronically within the timeframe specified.</li>
      </ul>
      <h3>5.2 Payment Methods</h3>
      <ul>
        <li>Accepted payment methods include direct bank transfer, Razorpay, and PayPal.</li>
      </ul>
      <h3>5.3 Refund Policy</h3>
      <ul>
        <li>No refunds will be issued for services rendered.</li>
        <li>Partial refunds may be considered solely at the discretion of Ripple Nexus if the company is unable to deliver on agreed deliverables due to internal constraints.</li>
      </ul>

      <div className="section-header">
        <h2>6. Confidentiality and Data Protection</h2>
      </div>
      <h3>6.1 Confidentiality</h3>
      <ul>
        <li>Ripple Nexus will not disclose client data or project details without explicit consent unless required by law.</li>
        <li>Both parties agree to protect sensitive data shared via emails, file transfers, or any online medium during the collaboration.</li>
      </ul>
      <h3>6.2 Data Security</h3>
      <ul>
        <li>Ripple Nexus uses secure systems and protocols to protect client data. However, no system is completely secure, and clients agree to hold Ripple Nexus harmless for breaches outside its reasonable control.</li>
      </ul>

      <div className="section-header">
        <h2>7. Limitation of Liability</h2>
      </div>
      <ul>
        <li>Ripple Nexus is not liable for losses resulting from misuse of deliverables by the client.</li>
        <li>Delays caused by circumstances beyond its control, including but not limited to natural disasters, cyberattacks, or third-party interruptions.</li>
        <li>Indirect, incidental, or consequential damages, including loss of revenue, profits, or business opportunities.</li>
        <li>Damages resulting from any misuse of online deliverables, digital tools, or services provided. Clients are solely responsible for the use of services, applications, or products deployed on their digital platforms.</li>
        <li>Ripple Nexus’s maximum liability for any claim is limited to the total fees paid by the client for the specific service.</li>
      </ul>

      <div className="section-header">
        <h2>8. Termination</h2>
      </div>
      <h3>8.1 Termination by Client</h3>
      <ul>
        <li>Clients may terminate the agreement at any time, subject to the payment of all fees for services rendered up to the termination date.</li>
      </ul>
      <h3>8.2 Termination by Ripple Nexus</h3>
      <ul>
        <li>Ripple Nexus may terminate the agreement in cases of non-payment, breach of obligations, or any act that undermines the service integrity.</li>
        <li>Ripple Nexus reserves the right to refuse or cease providing services if payment is not received within the stated period.</li>
      </ul>

      <div className="section-header">
        <h2>9. Dispute Resolution</h2>
      </div>
      <ul>
        <li>All disputes under this agreement will be resolved through arbitration according to the Indian Arbitration and Conciliation Act, 1996.</li>
        <li>Both parties agree to make a good-faith effort to resolve any dispute amicably before pursuing formal arbitration.</li>
      </ul>

      <div className="section-header">
        <h2>10. Governing Law</h2>
      </div>
      <p>These Terms shall be governed and construed in accordance with the laws of India, and any disputes shall be subject to the exclusive jurisdiction of the courts located in Noida, Uttar Pradesh.</p>

      <div className="section-header">
        <h2>11. Changes to Terms</h2>
      </div>
      <ul>
        <li>Ripple Nexus reserves the right to amend these Terms at any time by updating this page. The updated Terms will be effective immediately upon posting on our website.</li>
        <li>It is the client's responsibility to review these Terms periodically for any changes.</li>
      </ul>

      <p>If you have any questions regarding these Terms, please contact us at <a href="mailto:contact@ripplenexus.tech">contact@ripplenexus.tech</a>.</p>
    </div>
  );
};

export default TermsOfService;

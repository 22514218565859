import React from 'react';
import '../styles/css/PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="privacy-policy-container">
            <div className="sidebar">
                <h3>Table of Contents</h3>
                <ul>
                    <li><a href="#definitions">1. Definitions</a></li>
                    <li><a href="#info-collection">2. Information We Collect</a>
                        <ul>
                            <li><a href="#personal-info">2.1 Personal Information</a></li>
                            <li><a href="#auto-collected">2.2 Automatically Collected Data</a></li>
                            <li><a href="#third-party-sources">2.3 Third-Party Sources</a></li>
                            <li><a href="#cookies">2.4 Cookies and Similar Technologies</a></li>
                        </ul>
                    </li>
                    <li><a href="#how-use">3. How We Use Your Information</a></li>
                    <li><a href="#sharing">4. Sharing Your Information</a></li>
                    <li><a href="#data-retention">5. Data Retention</a></li>
                    <li><a href="#security">6. Data Security</a></li>
                    <li><a href="#rights">7. Your Rights</a></li>
                    <li><a href="#third-party">8. Third-Party Links</a></li>
                    <li><a href="#children">9. Children’s Privacy</a></li>
                    <li><a href="#cross-border">10. Cross-Border Transfers</a></li>
                    <li><a href="#updates">11. Updates to This Policy</a></li>
                </ul>
            </div>

            <div className="content">
                <h1 id="privacy-policy">Privacy Policy</h1>
                <p><strong>Effective Date:</strong> 1st August, 2024</p>
                <p><strong>Last Updated:</strong> 26th December, 2024</p>
                <p>
                    Ripple Nexus, accessible at <a href="https://www.ripplenexus.tech" style={{ color: '#61dafb' }}>https://www.ripplenexus.tech</a>, is dedicated to maintaining the privacy and protection of your personal data. This Privacy Policy details the types of information we collect, how it is used, stored, and shared, and your rights concerning that information.
                </p>
                <p>
                    By accessing our website or using our services, you agree to the terms of this Privacy Policy. If you do not agree, please refrain from using our services.
                </p>

                <h2 id="definitions">1. Definitions</h2>
                <ul>
                    <li><strong>"We," "Us," "Our":</strong> Ripple Nexus.</li>
                    <li><strong>"You," "User," "Client":</strong> Any individual or entity accessing or using our website and services.</li>
                    <li><strong>"Personal Information":</strong> Information that identifies you as an individual, such as name, contact details, and payment information.</li>
                    <li><strong>"Non-Personal Information":</strong> Data that does not directly identify you, such as IP address and browsing activity.</li>
                </ul>

                <h2 id="info-collection">2. Information We Collect</h2>
                <h3 id="personal-info">2.1 Personal Information</h3>
                <ul>
                    <li><strong>Contact Details:</strong> Name, phone number, email address, and postal address when you inquire about or use our services.</li>
                    <li><strong>Transactional Data:</strong> Billing address, payment details (processed through online payment gateways such as PayPal, Razorpay, or bank transfers), and order history for services purchased via our digital platforms.</li>
                    <li><strong>Professional Information:</strong> Resumes, portfolios, project details, and other work-related submissions.</li>
                    <li><strong>Account Information:</strong> If you create an account, we collect usernames, passwords, and preferences.</li>
                </ul>

                <h3 id="auto-collected">2.2 Automatically Collected Data</h3>
                <ul>
                    <li>1. Device type, browser type, operating system, and screen resolution.</li>
                    <li>2. IP address, geolocation, and time zone settings.</li>
                    <li>3. Browsing activity, including pages visited, time spent, and referral sources.</li>
                </ul>

                <h3 id="third-party-sources">2.3 Third-Party Sources</h3>
                <ul>
                    <li>1. Public databases for contact verification.</li>
                    <li>2. Marketing partners or social media platforms if you interact with our advertisements.</li>
                </ul>

                <h3 id="cookies">2.4 Cookies and Similar Technologies</h3>
                <ul>
                    <li>1. Facilitate site navigation and usage.</li>
                    <li>2. Analyze visitor behavior to improve functionality.</li>
                </ul>
                <p>You may disable cookies through your browser settings, but some features may not function optimally.</p>

                <h2 id="how-use">3. How We Use Your Information</h2>
                <ol>
                    <li><strong>1. Service Fulfillment:</strong> To process online inquiries, deliver digital services, and manage transactions via our website.</li>
                    <li><strong>2. Customer Support:</strong> To respond to digital inquiries, provide online troubleshooting, and deliver updates via email or chat.</li>
                    <li><strong>3. Improvement:</strong> To enhance user experience by analyzing website usage patterns and improving online services.</li>
                    <li><strong>4. Marketing:</strong> To send targeted digital offers, newsletters, and promotional materials via email or online channels (with opt-out options).</li>
                    <li><strong>5. Legal Compliance:</strong> To meet legal obligations applicable to online businesses and digital services.</li>
                </ol>

                <h2 id="sharing">4. Sharing Your Information</h2>
                <ul>
                    <li><strong>1. Payment Processors:</strong> For secure transaction handling.</li>
                    <li><strong>2. Hosting Providers:</strong> To manage our website and data storage.</li>
                    <li><strong>3. Legal and Regulatory Authorities:</strong> In response to legal obligations or government requests.</li>
                </ul>

                <h2 id="data-retention">5. Data Retention</h2>
                <ul>
                    <li>1. Service fulfillment.</li>
                    <li>2. Compliance with legal obligations.</li>
                    <li>3. Resolution of disputes or enforcement of agreements.</li>
                </ul>

                <h2 id="security">6. Data Security</h2>
                <ul>
                    <li>1. Encrypted communication (e.g., SSL/TLS protocols).</li>
                    <li>2. Access controls, firewalls, and regular system audits.</li>
                </ul>

                <h2 id="rights">7. Your Rights</h2>
                <ol>
                    <li><strong>1. Access Your Data:</strong> Request a copy of your personal information.</li>
                    <li><strong>2. Correct Errors:</strong> Rectify inaccurate or incomplete data.</li>
                    <li><strong>3. Delete Data:</strong> Request data erasure under specific conditions.</li>
                    <li><strong>4. Restrict Processing:</strong> Limit how your data is used in certain situations.</li>
                    <li><strong>5. Data Portability:</strong> Receive your data in a machine-readable format.</li>
                    <li><strong>6. Opt-Out:</strong> Decline marketing communications or withdraw consent where processing is based on consent.</li>
                </ol>

                <h2 id="third-party">8. Third-Party Links</h2>
                <p>Our website may include links to external websites or digital platforms. Ripple Nexus is not responsible for the privacy practices or tracking mechanisms employed by these third-party platforms.</p>

                <h2 id="children">9. Children’s Privacy</h2>
                <p>Our services are not intended for individuals under 18 years of age. We do not knowingly collect data from minors.</p>

                <h2 id="cross-border">10. Cross-Border Transfers</h2>
                <p>Data may be transferred across borders and processed in countries with varying levels of data protection. We ensure that such transfers comply with applicable international data protection laws and implement appropriate safeguards.</p>

                <h2 id="updates">11. Updates to This Policy</h2>
                <p>We reserve the right to modify this Privacy Policy at our discretion. Any changes will take effect immediately upon posting on our website. Significant changes will be communicated via email or prominent notices on the site to ensure that you are informed of any updates.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
import * as React from "react";
import { QuoteProps } from "./types";

export const Quote: React.FC<QuoteProps> = ({ author, signatureImageSrc, designation }) => {
    return (
        <div className="flex gap-6 flex-col items-start self-start mt-9 text-2xl font-semibold text-white">
            <div className="grow">~ {author}</div>
            <div className="grow text-sm mt-0">{designation}</div>
            {/* <img
                loading="lazy"
                src={signatureImageSrc}
                alt={`${author}'s signature`}
                className="object-contain shrink-0 mt-1.5 rounded-none aspect-[0.57] w-[33px]"
            /> */}
        </div>
    );
};
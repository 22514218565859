import * as React from "react";
import { ImageCardProps } from "./types";

export const ImageCard: React.FC<ImageCardProps> = ({ src, width, aspectRatio, marginTop = "" }) => {
    return (
        <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
            <img
                loading="lazy"
                src={src}
                alt=""
                className={`object-contain grow shrink-0 ${marginTop} max-w-full rounded-none ${aspectRatio} ${width} max-md:mt-10`}
            />
        </div>
    );
};
import * as React from "react";
import { ImageCard } from "./ImageCard";
import { Quote } from "./Quote";

const imageData = [
    {
        src: "https://cdn.builder.io/api/v1/image/assets/8087bcd0ce0c468e9ae5395d126ea30e/dbc394da38110f3fdeb0b0162778b873d58bc0d0e754d57b8e573a316c2ec171?apiKey=8087bcd0ce0c468e9ae5395d126ea30e&",
        width: "w-[201px]",
        aspectRatio: "aspect-[0.55]",
        marginTop: "mt-5"
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/8087bcd0ce0c468e9ae5395d126ea30e/a5b20ee9fa6a88b4183199800c9d8af86dbf969d76184ee3dac5cd50e156f1f0?apiKey=8087bcd0ce0c468e9ae5395d126ea30e&",
        width: "w-[202px]",
        aspectRatio: "aspect-[0.55]",
        marginTop: "mt-5"
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/8087bcd0ce0c468e9ae5395d126ea30e/946333170cad7e0f14a26369158a1b8258100e799c351822a98960ead6f71488?apiKey=8087bcd0ce0c468e9ae5395d126ea30e&",
        width: "w-[203px]",
        aspectRatio: "aspect-[0.54]",
        marginTop: "mt-1"
    },
    {
        src: "https://cdn.builder.io/api/v1/image/assets/8087bcd0ce0c468e9ae5395d126ea30e/6ab4c955aea81dfd8357457154ee2c4d682101329a267c715cb161ce5fd06902?apiKey=8087bcd0ce0c468e9ae5395d126ea30e&",
        width: "w-[205px]",
        aspectRatio: "aspect-[0.54]",
        marginTop: ""
    }
];

export const CompanyVision: React.FC = () => {
    return (
        <div className="flex flex-col rounded-none">
            <div className="flex flex-col items-center px-20 pt-11 pb-5 w-full bg-blue-600 max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col ml-2.5 w-full max-w-[996px] max-md:max-w-full">
                    <div className="self-start text-5xl font-semibold text-white max-md:text-4xl">
                        Company Vision
                    </div>
                    <div className="max-md:max-w-full max-sm:mx-auto">
                        <div className="flex gap-5 max-sm:flex-col">
                            {imageData.map((image, index) => (
                                <ImageCard
                                    key={index}
                                    src={image.src}
                                    width={image.width}
                                    aspectRatio={image.aspectRatio}
                                    marginTop={image.marginTop}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="mt-10 mr-12 text-xl leading-6 text-white max-md:mr-2.5 max-md:max-w-full">
                        I chose the name '
                        <span className="font-semibold text-white">Ripple Nexus</span>' to
                        reflect our core philosophy and strategic vision. <br />
                        The 'Ripple' symbolizes our belief in the power of small, thoughtful
                        actions to create waves of significant change. It represents our
                        approach to innovation—starting with a focused initiative that
                        expands its influence far and wide.
                        <br />
                        <br /> 'Nexus,' on the other hand, signifies being a central point
                        of connection, where diverse ideas, talents, and technologies come
                        together to create something much greater than the sum of its parts.
                    </div>
                    <Quote
                        author="Ashutosh Shukla"
                        designation="Founder, Ripple Nexus"
                        signatureImageSrc="https://cdn.builder.io/api/v1/image/assets/8087bcd0ce0c468e9ae5395d126ea30e/c0964d2a6a7720cede382c0c6e4927fc6497adb4e3de458db1fbfa13c3950698?apiKey=8087bcd0ce0c468e9ae5395d126ea30e&"
                    />
                </div>
            </div>
        </div>
    );
};